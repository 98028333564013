import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';

import {Action} from '../typescript/actionTypes';
import { blobToBase64 } from 'file64';
import DeserifyHelper from '../helpers/DeserifyHelper';
import ErrorHelper from '../helpers/errorHelper';
import invoiceApi from '../api/invoiceApi';

function* searchInvoice(action: Action): Generator<any, any, any> {
    try {
        const invoice = yield call(invoiceApi.searchInvoice, action.payload.invoiceNumber);
        const pdf = yield call(invoiceApi.getInvoice, invoice.id, action.payload.invoiceNumber);

        let base64;
        yield blobToBase64(pdf.blob).then(r => base64 = r);

        yield put({type: ActionTypes.SEARCH_INVOICE_SUCCEEDED, payload: {pdf: {fileName: pdf.fileName, base64 : base64, download: action.payload.download}}});
    } catch (e) {
        ErrorHelper.showMessageOnNoContent(e);
        yield put({type: ActionTypes.SEARCH_INVOICE_FAILED, payload: null});
    }
}

function* getSentInvoices(action: Action): Generator<any, any, any> {
    try {
        const payload = DeserifyHelper.deserify(action.payload);
        const sortingStyle = { sortOrder: payload.sortOrder, sortBy: payload.sortBy };
        const sentInvoices = yield call(invoiceApi.getSentInvoices, payload.icPartnerId, payload.page, payload.invoiceDateRangeStart, payload.invoiceDateRangeEnd, payload, sortingStyle);

        yield put({type: ActionTypes.GET_SENT_INVOICES_SUCCEEDED, payload: sentInvoices});
    } catch (e) {
        const sentInvoices: any[] = [];
        yield put({type: ActionTypes.GET_SENT_INVOICES_FAILED, payload: sentInvoices});
    }
}

function* searchInvoiceNumbers(action: Action): Generator<any, any, any> {
    try {
        const invoiceNumbers = yield call(invoiceApi.searchInvoiceNumber, action.payload.invoiceNumber, action.payload.page);
        yield put({type: ActionTypes.SEARCH_INVOICENUMBERS_SUCCEEDED, payload: invoiceNumbers});
    } catch (e) {
        const invoiceNumbers: any[] = [];
        yield put({type: ActionTypes.SEARCH_INVOICENUMBERS_FAILED, payload: invoiceNumbers});
    }
}

function* clearInvoiceNumbers(): Generator<any, any, any> {
    yield put({type: ActionTypes.CLEAR_INVOICE_NUMBERS_SUCCEEDED});
}

function* getInvoice(action: Action): Generator<any, any, any> {
    try {
        const pdf = yield call(invoiceApi.getInvoice, action.payload.invoiceId, action.payload.invoiceNumber);

        let base64;
        yield blobToBase64(pdf.blob).then(r => base64 = r);
        yield put({type: ActionTypes.GET_INVOICE_SUCCEEDED, payload: {pdf: {fileName: pdf.fileName, base64 : base64}}});
    } catch (e) {
        ErrorHelper.showMessageOnNoContent(e);

        yield put({type: ActionTypes.GET_INVOICE_FAILED, payload: null});
    }
}

function* invoiceSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.SEARCH_INVOICE_REQUESTED, searchInvoice);
    yield takeEvery(ActionTypes.GET_SENT_INVOICES_REQUESTED, getSentInvoices);
    yield takeEvery(ActionTypes.SEARCH_INVOICENUMBERS_REQUESTED, searchInvoiceNumbers);
    yield takeEvery(ActionTypes.CLEAR_INVOICE_NUMBERS_REQUESTED, clearInvoiceNumbers);
    yield takeEvery(ActionTypes.GET_INVOICE_REQUESTED, getInvoice);
}

export default invoiceSaga;
