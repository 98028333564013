import { MessageType } from './typescript/messageTypes';

export default class CustomError extends Error {
    messageType: MessageType;
    status: number;

    constructor(messageType: MessageType, message: string) {
        super(message);
        this.messageType = messageType;
        this.status = 0;
    }
}
