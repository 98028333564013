import {AdfsTokenModel, UserCredentials} from '../typescript/userTypes';
import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class UserApi {
    static loginUser(user: UserCredentials): Promise<any> {
        user.username = user.username.toLowerCase().indexOf('iconos\\') !== -1 ? user.username.substring(user.username.indexOf('\\') + 1, user.username.length) : user.username;
        if (user.username.indexOf('@cronos.be') !== -1) {
            user.username = user.username.replace('@cronos.be', '');
        }
        return AuthorizedRequest.postAuthorizedRequest('token', user)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static loginAdfsUser(token: AdfsTokenModel): Promise<any> {
        return AuthorizedRequest.postAuthorizedRequest('token/adfs', token)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static forceRefreshToken(): void {
        AuthorizedRequest.forceRefreshToken();
    }

    static getUser(username: string): Promise<any> {
        return AuthorizedRequest.getAuthorizedRequest(`user/${encodeURIComponent(username)}`)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getPermissions(): Promise<any> {
        return AuthorizedRequest.getAuthorizedRequest('permission/authPermissions')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getVisibleUsers(): Promise<any> {
        return AuthorizedRequest.getAuthorizedRequest('user')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getVisibleSuppliers(): Promise<any> {
        return AuthorizedRequest.getAuthorizedRequest('user/allvisiblecompanies')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getInvoiceViewerSuppliers(): Promise<any> {
        return AuthorizedRequest.getAuthorizedRequest('user/invoiceviewersuppliers')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static getActiveUsersWithGroups(groupIds?: number[]): Promise<any> {
        const url = groupIds === undefined ? 'user/groups' : `user/groups?groupIds=${groupIds.join(',')}`;

        return AuthorizedRequest.getAuthorizedRequest(url)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static resetPassword(emailAddress: string): Promise<any> {
        return AuthorizedRequest.resetPassword(emailAddress)
            .ok(res => {
                return res.status === 200;
            })
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default UserApi;
