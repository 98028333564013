import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class DocumentApi {
    static getAssignmentDocument(payload: any) {
        return AuthorizedRequest.getAuthorizedRequest(`document/assignmentdocument/${payload.assignmentDocumentId}`)
            .ok(res => res.status === 200)
            .responseType('blob')
            .then(response => {
                const customResponse = {
                    blob: response.body,
                    fileName: payload.filename
                };
                return customResponse;
            })
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
    static deleteAssignmentDocument(payload: any) {
        return AuthorizedRequest.deleteAuthorizedRequest(`document/assignmentdocument/${payload.assignmentDocumentId}`)
            .ok(res => res.status === 200)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default DocumentApi;
