import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {InitialState} from './initialState';
import {StoreMaintenanceDetails} from '../typescript/storeTypes';
import ErrorHelper from '../helpers/errorHelper';

export default function (state: StoreMaintenanceDetails = InitialState.maintenanceDetails, action: Action): StoreMaintenanceDetails {

    switch (action.type) {
        case ActionTypes.MAINTENANCE_DETAILS_SUCCEEDED: {
            return Object.assign({}, state, {
                isInMaintenanceMode: action.payload.isInMaintenanceMode,
                maintenanceScheduleStartTime: action.payload.maintenanceScheduleStartTime,
                maintenanceScheduleEndTime: action.payload.maintenanceScheduleEndTime
            });
        }
        case ActionTypes.MAINTENANCE_DETAILS_FAILED: {
            const pattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/g;

            const startDateMatch = pattern.exec(action.payload);
            if(startDateMatch !== null)
            {
                const endDateMatch = pattern.exec(action.payload);

                const startTime = new Date(startDateMatch as any);
                const endTime = new Date(endDateMatch as any);

                return Object.assign({}, state, {
                                                isInMaintenanceMode: true,
                                                maintenanceScheduleStartTime: new Date(startTime),
                                                maintenanceScheduleEndTime: new Date(endTime),
                                                isLatestVersion: true
                                            });
            }
            return state;
        }
        case ActionTypes.CHECK_VERSION_SUCCEEDED: {
            return Object.assign({}, state, {isLatestVersion: action.payload.isLatest});
        }
        case ActionTypes.TIMESHEET_REQUEST_FAILED:
        case ActionTypes.ADD_ENTRY_FAILED:
        case ActionTypes.DELETE_ENTRY_FAILED:
        case ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_FAILED:
        case ActionTypes.EXPORT_MONTHLY_TIMESHEET_FAILED:
        case ActionTypes.EXPORT_WEEKLY_TIMESHEET_FAILED:
        case ActionTypes.MOST_RECENT_TSCODES_FAILED:
        case ActionTypes.PERMISSIONS_FAILED:
        case ActionTypes.SUPPLIERS_FAILED:
        case ActionTypes.TIMESHEET_COMPLETE_FAILED:
        case ActionTypes.TIMESHEET_INCOMPLETE_FAILED:
        case ActionTypes.TIMESHEETCODE_COMPLETE_FAILED:
        case ActionTypes.TIMESHEETCODE_DELETE_FAILED:
        case ActionTypes.TIMESHEETCODE_INCOMPLETE_FAILED:
        case ActionTypes.SUPPLIERS_EMPLOYEES_FAILED:
        case ActionTypes.TSCODES_FAILED:
        case ActionTypes.UPDATE_ENTRY_FAILED:
        case ActionTypes.USER_FAILED:
        case ActionTypes.VISIBLE_USERS_FAILED:
        case ActionTypes.VISIBLE_SUPPLIERS_FAILED:
        case ActionTypes.ADD_ASSIGNMENT_FAILED: {
            if(ErrorHelper.errorHasStatusCode(action.payload, 503)) {
                return Object.assign({}, state, {isInMaintenanceMode: true});
            }

            return state;
        }
        default:
            return state;
    }
}
