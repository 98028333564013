import {ProjectBasedTimesheetReportType} from '../typescript/reportTypes';
import AuthorizedRequest from './authorizedRequests';
import ErrorHelper from '../helpers/errorHelper';

class ProjectApi {
    static getAllProjects(){
        return AuthorizedRequest.getAuthorizedRequest('project')
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }

    static generateProjectReport(project: ProjectBasedTimesheetReportType){
        return AuthorizedRequest.postAuthorizedRequest(`project/${project.id}/report`, project)
            .ok(res => res.status === 200)
            .then(response => response.body)
            .catch(ErrorHelper.getCustomWebsiteErrorFunction());
    }
}

export default ProjectApi;
