import { configureStore } from '@reduxjs/toolkit';
import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware';

import {InitialState} from '../reducers/initialState';
import {MessageTypes} from '../types/messageTypes';

import {
    createReduxMiddleware,
    defaultOptions,
  } from '@karmaniverous/serify-deserify';
import CookieHelper from '../helpers/cookieHelper';
import CustomError from '../customError';
import MessageHelper from '../helpers/messageHelper';
import assignmentSaga from '../sagas/assignmentSaga';
import createSagaMiddleware from 'redux-saga';
import documentSaga from '../sagas/documentSaga';
import groupSaga from '../sagas/groupSaga';
import importSaga from '../sagas/importSaga';
import invoiceSaga from '../sagas/invoiceSaga';
import maintenanceSaga from '../sagas/maintenanceSaga';
import presetSaga from '../sagas/presetSaga';
import projectSaga from '../sagas/projectSaga';
import reduxCatch from 'redux-catch';
import releaseNoteSaga from '../sagas/releaseNoteSaga';
import reportSaga from '../sagas/reportSaga';
import rootReducer from '../reducers/rootReducer';
import signingHubSaga from '../sagas/signingHubSaga';
import supplierSaga from '../sagas/supplierSaga';
import timesheetSaga from '../sagas/timesheetSaga';
import tsCodeSaga from '../sagas/tsCodeSaga';
import userSaga from '../sagas/userSaga';


let initialState = Object.assign({},  JSON.parse(JSON.stringify(InitialState)), {timesheet: InitialState.timesheet});

const paths = CookieHelper.getPaths();

initialState = getStateFromCookies(initialState, paths, (name: string) => CookieHelper.getCookie(name));

function errorHandler(error: Error|CustomError) {
    const customError = error as CustomError;
    if(customError?.messageType) {
        MessageHelper.showMessage({type: customError.messageType, message: customError.message});
        return;
    }
    const err = error as Error;
    if(err?.message) {
        MessageHelper.showMessage({type: MessageTypes.ERROR, message: err.message});
    }
}

const sagaMiddleware = createSagaMiddleware();
const serifyMiddleware = createReduxMiddleware(defaultOptions);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware();

        return defaultMiddleware.concat([
            reduxCookiesMiddleware(paths, { setCookie: (name: string,  value: string) => CookieHelper.setCookie(name, value)}),
            reduxCatch(errorHandler),
            sagaMiddleware,
            serifyMiddleware
        ]);
    },
    preloadedState: initialState
});

sagaMiddleware.run(userSaga);
sagaMiddleware.run(timesheetSaga);
sagaMiddleware.run(signingHubSaga);
sagaMiddleware.run(tsCodeSaga);
sagaMiddleware.run(reportSaga);
sagaMiddleware.run(assignmentSaga);
sagaMiddleware.run(supplierSaga);
sagaMiddleware.run(maintenanceSaga);
sagaMiddleware.run(importSaga);
sagaMiddleware.run(releaseNoteSaga);
sagaMiddleware.run(invoiceSaga);
sagaMiddleware.run(presetSaga);
sagaMiddleware.run(documentSaga);
sagaMiddleware.run(projectSaga);
sagaMiddleware.run(groupSaga);
