import {Action} from '../typescript/actionTypes';
import {ActionTypes} from '../types/actionTypes';
import {call, put, select, takeEvery} from 'redux-saga/effects';

import { Messages } from '../types/messageTypes';
import { Period } from '../typescript/dateTypes';
import { StoreState } from '../typescript/storeTypes';
import { blobToBase64 } from 'file64';
import DeserifyHelper from '../helpers/DeserifyHelper';
import ErrorHelper from '../helpers/errorHelper';
import MessageHelper from '../helpers/messageHelper';
import timesheetApi from '../api/timesheetApi';

function* getTimesheet(action: Action): Generator<any, any, any> {
    try {
        yield put({
            type: ActionTypes.TIMESHEET_REQUEST_SUCCEEDED,
            payload: {
                period: action.payload.period,
                timesheetCodes: yield call(timesheetApi.getTimesheet, DeserifyHelper.deserify<Period>(action.payload.period), action.payload.employee)
            }
        });

        const settings = yield select((state: StoreState) => state.settings);

        if (settings.monthViewSelected && action.payload.period.days.length === 7) {
            yield put({type: ActionTypes.UPDATE_SETTING, payload: {monthViewSelected: false}});

        } else if (!settings.monthViewSelected && action.payload.period.days.length !== 7) {
            yield put({type: ActionTypes.UPDATE_SETTING, payload: {monthViewSelected: true}});
        }
    }
    catch (e) {
        ErrorHelper.showMessageNoRethrow(e);
        yield put({type: ActionTypes.TIMESHEET_REQUEST_FAILED, payload: {
            period: action.payload.period
        }});
    }
}

function* postTimesheetEntry(action: Action): Generator<any, any, any> {
    try {
        let entry;
        if(action.payload.useUsername)
        {
            entry = yield call(timesheetApi.postTimesheetEntry, action.payload.entry, action.payload.entryInfo);
        } else {
            entry = yield call(timesheetApi.postTimesheetEntryCurrentUser, action.payload.entry, action.payload.entryInfo);
        }
        yield put({
            type: ActionTypes.ADD_ENTRY_SUCCEEDED,
            payload: {entry: entry, entryInfo: action.payload.entryInfo}
        });
    } catch (e) {
        yield put({type: ActionTypes.ADD_ENTRY_FAILED, payload: e.message});
    }
}

function* putTimesheetEntry(action: Action): Generator<any, any, any> {
    try {
        let entry;
        if(action.payload.useUsername)
        {
            entry = yield call(timesheetApi.putTimesheetEntry, action.payload.entry, action.payload.entryInfo);
        } else {
            entry = yield call(timesheetApi.putTimesheetEntryCurrentUser, action.payload.entry);
        }
        yield put({
            type: ActionTypes.UPDATE_ENTRY_SUCCEEDED,
            payload: {entry: entry, entryInfo: action.payload.entryInfo}
        });
    } catch (e) {
        yield put({type: ActionTypes.UPDATE_ENTRY_FAILED, payload: e.message});
        ErrorHelper.showMessageNoRethrow(e);
    }
}

function* deleteTimesheetEntry(action: Action): Generator<any, any, any> {
    try {
        if(action.payload.useUsername)
        {
            yield call(timesheetApi.deleteTimesheetEntry, action.payload.entry, action.payload.entryInfo);
        } else {
            yield call(timesheetApi.deleteTimesheetEntryCurrentUser, action.payload.entry);
        }

        yield put({
            type: ActionTypes.DELETE_ENTRY_SUCCEEDED,
            payload: {entryId: action.payload.entry.id, tsCodeId: action.payload.entryInfo.tsCodeId}
        });
    } catch (e) {
        yield put({type: ActionTypes.DELETE_ENTRY_FAILED, payload: e.message});
        ErrorHelper.showMessageNoRethrow(e);
    }
}

function* postTimesheetComplete(action: Action): Generator<any, any, any> {
    try {
        if(action.payload.useUsername)
        {
            yield call(timesheetApi.postTimesheetComplete, action.payload);
        }
        else {
            yield call(timesheetApi.postTimesheetCompleteCurrentUser, action.payload);
        }
        yield put({type: ActionTypes.TIMESHEET_COMPLETE_SUCCEEDED, payload: null});
    } catch (e) {
        yield put({type: ActionTypes.TIMESHEET_COMPLETE_FAILED, payload: e.message});
    }
}

function* postTimesheetIncomplete(action: Action): Generator<any, any, any> {
    try {
        if(action.payload.useUsername)
        {
            yield call(timesheetApi.postTimesheetIncomplete, action.payload);
        }
        else {
            yield call(timesheetApi.postTimesheetIncompleteCurrentUser, action.payload);
        }
        yield put({type: ActionTypes.TIMESHEET_INCOMPLETE_SUCCEEDED, payload: null});
    } catch (e) {
        yield put({type: ActionTypes.TIMESHEET_INCOMPLETE_FAILED, payload: e.message});
    }
}

function* postExportMonthlyTimesheet(action: Action): Generator<any, any, any> {
    try {
        const exportedTimesheet = yield call(timesheetApi.postExportMonthlyTimesheet, DeserifyHelper.deserify(action.payload));
        if(exportedTimesheet.size === 0) return; //this happens when it mails

        let base64;
        yield blobToBase64(exportedTimesheet).then(r => base64 = r);

        yield put({type: ActionTypes.EXPORT_MONTHLY_TIMESHEET_SUCCEEDED, payload: base64});
    } catch (e) {
        yield put({type: ActionTypes.EXPORT_MONTHLY_TIMESHEET_FAILED, payload: e.message});
    }
}

function* postExportMonthlyTimesheetExcel(action: Action): Generator<any, any, any> {
    try {
        const exportedTimesheet = yield call(timesheetApi.postExportMonthlyTimesheetExcel, DeserifyHelper.deserify(action.payload));
        if(exportedTimesheet.size === 0) return; //this happens when it mails

        let base64;
        yield blobToBase64(exportedTimesheet).then(r => base64 = r);

        yield put({type: ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_SUCCEEDED, payload: base64});
    } catch (e) {
        yield put({type: ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_FAILED, payload: e.message});
    }
}

function* postExportWeeklyTimesheet(action: Action): Generator<any, any, any> {
    try {
        const exportedTimesheet = yield call(timesheetApi.postExportWeeklyTimesheet, DeserifyHelper.deserify(action.payload));
        if(exportedTimesheet.size === 0) return; //this happens when it mails

        let base64;
        yield blobToBase64(exportedTimesheet).then(r => base64 = r);

        yield put({type: ActionTypes.EXPORT_WEEKLY_TIMESHEET_SUCCEEDED, payload: base64});
    } catch (e) {
        yield put({type: ActionTypes.EXPORT_WEEKLY_TIMESHEET_FAILED, payload: e.message});
    }
}

function* postExportWeeklyTimesheetExcel(action: Action): Generator<any, any, any> {
    try {
        const exportedTimesheet = yield call(timesheetApi.postExportWeeklyTimesheetExcel, DeserifyHelper.deserify(action.payload));
        if(exportedTimesheet.size === 0) return; //this happens when it mails

        let base64;
        yield blobToBase64(exportedTimesheet).then(r => base64 = r);

        yield put({type: ActionTypes.EXPORT_WEEKLY_TIMESHEET_EXCEL_SUCCEEDED, payload: base64});
    } catch (e) {
        yield put({type: ActionTypes.EXPORT_WEEKLY_TIMESHEET_EXCEL_FAILED, payload: e.message});
    }
}

function* getIncompleteTimesheets(): Generator<any, any, any> {
    try {
        const incompleteMonths = yield call(timesheetApi.getIncompleteTimesheets);
        yield put({type: ActionTypes.GET_INCOMPLETE_TIMESHEETS_SUCCEEDED, payload: incompleteMonths});
    } catch (e) {
        yield put({type: ActionTypes.GET_INCOMPLETE_TIMESHEETS_FAILED, payload: e.message});
    }
}

function* postClientTimesheet(action: Action): Generator<any, any, any> {
    try {
        let result: any = undefined;

        if(action.payload.useUsername)
        {
            result = yield call(timesheetApi.postClientTimesheet, action.payload);
        }
        else {
            result = yield call(timesheetApi.postClientTimesheetCurrentUser, action.payload);
        }

        MessageHelper.showMessage(Messages.UploadedDocumentSuccessfully);
        yield put({type: ActionTypes.UPLOAD_CLIENTTIMESHEET_SUCCEEDED, payload: Object.assign({}, action.payload, {assignmentDocumentId: result.id})});
    } catch (e) {
        yield put({type: ActionTypes.UPLOAD_CLIENTTIMESHEET_FAILED, payload: e.message});

        ErrorHelper.showMessageNoRethrow(e);
    }
}

function* getSigningHubOverview(action: Action): Generator<any, any, any> {
    try {
        const assignmentIds = action.payload;

        const overview = yield call(timesheetApi.getSigningHubOverviewByAssignment, assignmentIds);
        yield put({type: ActionTypes.GET_SIGNINGHUB_OVERVIEW_SUCCEEDED, payload: overview});
    } catch (e) {
        yield put({type: ActionTypes.GET_SIGNINGHUB_OVERVIEW_FAILED, payload: e.message});
    }
}

function* timesheetSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.TIMESHEET_REQUESTED, getTimesheet);
    yield takeEvery(ActionTypes.ADD_ENTRY_REQUESTED, postTimesheetEntry);
    yield takeEvery(ActionTypes.UPDATE_ENTRY_REQUESTED, putTimesheetEntry);
    yield takeEvery(ActionTypes.DELETE_ENTRY_REQUESTED, deleteTimesheetEntry);
    yield takeEvery(ActionTypes.TIMESHEET_COMPLETE_REQUESTED, postTimesheetComplete);
    yield takeEvery(ActionTypes.TIMESHEET_INCOMPLETE_REQUESTED, postTimesheetIncomplete);
    yield takeEvery(ActionTypes.EXPORT_MONTHLY_TIMESHEET_REQUESTED, postExportMonthlyTimesheet);
    yield takeEvery(ActionTypes.EXPORT_MONTHLY_TIMESHEET_EXCEL_REQUESTED, postExportMonthlyTimesheetExcel);
    yield takeEvery(ActionTypes.EXPORT_WEEKLY_TIMESHEET_REQUESTED, postExportWeeklyTimesheet);
    yield takeEvery(ActionTypes.EXPORT_WEEKLY_TIMESHEET_EXCEL_REQUESTED, postExportWeeklyTimesheetExcel);
    yield takeEvery(ActionTypes.UPLOAD_CLIENTTIMESHEET_REQUESTED, postClientTimesheet);
    yield takeEvery(ActionTypes.GET_INCOMPLETE_TIMESHEETS_REQUESTED, getIncompleteTimesheets);
    yield takeEvery(ActionTypes.GET_SIGNINGHUB_OVERVIEW_REQUESTED, getSigningHubOverview);
}

export default timesheetSaga;
