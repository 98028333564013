import { MessageTypes } from '../types/messageTypes';
import CustomError from '../customError';
import MessageHelper from './messageHelper';

class ErrorHelper {
    static getCustomWebsiteErrorFunction() {
        return (error: any) => {
            throw this.GetCustomErrorObject(error);
        };
    }

    static showMessageNoRethrow(error: any) {
        const customError = this.GetCustomErrorObject(error);
        MessageHelper.showMessage({type: customError.messageType, message: customError.message});
    }

    static showMessageOnNoContent(customError: CustomError) {
        if(customError.messageType === MessageTypes.INFO)
        {
            MessageHelper.showMessage({type: customError.messageType, message: customError.message});
        }
    }

    static errorHasStatusCode(error: any, ...statusCodes: number[]) : boolean {
        return statusCodes.map(s => s?.toString()).includes(error?.toString()) ||
               statusCodes.map(s => s?.toString()).includes(error?.status?.toString()) ||
               statusCodes.map(s => s?.toString()).includes(error?.message?.toString());
    }

    private static GetCustomErrorObject(error: any) : CustomError
    {
        const customError = new CustomError(MessageTypes.ERROR, 'Unknown error occurred.');
        if(error) {
            if(error.status) customError.status = error.status;
            const response = error.response;
            if(response) {
                if(response.body) {
                    if(response.body.error) {
                        customError.message = response.body.error;
                        return customError;
                    }
                    if(response.body.errors) {
                        customError.message = response.body.errors.map((e: { message: any }) => e.message).join('\r\n');
                        return customError;
                    }
                }

                if(response.noContent | response.badRequest | response.notFound) {
                    customError.messageType = MessageTypes.INFO;

                    if(response.noContent) {
                        customError.message = 'No content';
                        return customError;
                    }

                    if(response.badRequest) {
                        customError.message = 'Bad request';
                        return customError;
                    }

                    if(response.notFound) {
                        customError.message ='Not found';
                        return customError;
                    }
                }

                if(error.status) {
                    customError.message = error.status;
                    return customError;
                }
            } else if(error.status) {
                customError.status = error.status;
                if(error.message) customError.message = error.message;
                if(!error.message) customError.message = error.status;
                return customError;
            } else if(error.message) {
                customError.message = error.message;
                return customError;
            }
        }
        return customError;
    }
}

export default ErrorHelper;
