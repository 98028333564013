import { StoreTimeLeftOnOrder } from '../../../../typescript/storeTypes';
import React from 'react';

class TimeLeftOnOrderDetails extends React.PureComponent<Props, State> {
    state = {
        actualsChecked: true,
        extraChecked: false,
        standbyChecked: false,
        interventionChecked: false,
    };

    round2dec = (num: number): number => Math.round(num * 100) / 100;

    getHoursPerDay = (): number => {
        return this.props.timeLeftOnOrder.hoursForCalculation;
    };

    getTotalHoursOnOrder = (): number => {
        return this.props.timeLeftOnOrder.daysOnOrder * this.getHoursPerDay();
    };

    getTotalHoursLeftOnOrder = (): number => {
        return this.getTotalHoursOnOrder() - this.getAllDeductableHours();
    };

    getAllDeductableHours = (): number => {
        return (this.state.actualsChecked ? this.props.timeLeftOnOrder.actualHoursOnOrder : 0) +
            (this.state.extraChecked ? this.props.timeLeftOnOrder.extraHoursOnOrder : 0) +
            (this.state.standbyChecked ? this.props.timeLeftOnOrder.standbyHoursOnOrder : 0) +
            (this.state.interventionChecked ? this.props.timeLeftOnOrder.interventionHoursOnOrder : 0);
    };

    getTotalDaysLeftOnOrder = (): number => {
        return this.getTotalHoursLeftOnOrder() / this.getHoursPerDay();
    };

    getHourOrDayPrice = (): string => {
        const unit = this.props.timeLeftOnOrder.isHourPrice ? 'per hour' : 'per day';
        if (this.props.timeLeftOnOrder.actualPrice === null)
            return 'No price found';

        return `${this.props.timeLeftOnOrder.actualPrice} ${this.props.timeLeftOnOrder.currency} ${unit}`;
    };

    render() {
        return (
            <div className="time-left-on-order-container">
                <div className="time-left-on-order-details">
                    <div className="time-left-on-order-details__title">Timesheet code name</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.tsCodeName}</div>
                    <div className="time-left-on-order-details__title">Consultants</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.consultants}</div>
                    <div className="time-left-on-order-details__title">Order number</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.orderNumber}</div>
                    <div className="time-left-on-order-details__title">Orderline number</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.orderLineNumber}</div>
                    <div className="time-left-on-order-details__title">IcPartner</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.icPartner}</div>
                    <div className="time-left-on-order-details__title">Actual Hours</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.actualHoursOnOrder}</div>
                    <div className="time-left-on-order-details__title">Extra Hours</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.extraHoursOnOrder}</div>
                    <div className="time-left-on-order-details__title">Standby Hours</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.standbyHoursOnOrder}</div>
                    <div className="time-left-on-order-details__title">Intervention Hours</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.interventionHoursOnOrder}</div>
                    <div className="time-left-on-order-details__title">Days on Order</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.daysOnOrder}</div>
                    <div className="time-left-on-order-details__title">Hours for Calculation</div>
                    <div className="time-left-on-order-details__item">{this.props.timeLeftOnOrder.hoursForCalculation}</div>
                    <div className="time-left-on-order-details__title">Price on order</div>
                    <div className="time-left-on-order-details__item">{this.getHourOrDayPrice()}</div>
                </div>
                {this.props.timeLeftOnOrder.daysOnOrder === 0 ?
                    <div className="time-left-on-order-calculated-fields">
                        <div className="time-left-on-order-details__title">No days on order present.</div>
                    </div>
                    :
                    <div className="time-left-on-order-calculated-fields">
                        <div className="time-left-on-order-calculated-fields-checkbox">
                            <div className="time-left-on-order-calculated-fields-checkbox__checkbox">
                                <div className="time-left-on-order-calculated-fields-checkbox__checkbox-text">Actuals</div>
                                <input id="actuals" type="checkbox" checked={this.state.actualsChecked} onClick={() => this.setState({actualsChecked: !this.state.actualsChecked})}/>
                            </div>
                            <div className="time-left-on-order-calculated-fields-checkbox__checkbox">
                                <div className="time-left-on-order-calculated-fields-checkbox__checkbox-text">Extra</div>
                                <input id="extra" type="checkbox" checked={this.state.extraChecked} onClick={() => this.setState({extraChecked: !this.state.extraChecked})}/>
                            </div>
                            <div className="time-left-on-order-calculated-fields-checkbox__checkbox">
                                <div className="time-left-on-order-calculated-fields-checkbox__checkbox-text">Standby</div>
                                <input id="standby" type="checkbox" checked={this.state.standbyChecked} onClick={() => this.setState({standbyChecked: !this.state.standbyChecked})}/>
                            </div>
                            <div className="time-left-on-order-calculated-fields-checkbox__checkbox">
                                <div className="time-left-on-order-calculated-fields-checkbox__checkbox-text">Intervention</div>
                                <input id="intervention" type="checkbox" checked={this.state.interventionChecked} onClick={() => this.setState({interventionChecked: !this.state.interventionChecked})}/>
                            </div>
                        </div>
                        <div className="time-left-on-order-details__title">Total hours on order</div>
                        <div className="time-left-on-order-details__item">{this.round2dec(this.getTotalHoursOnOrder())}</div>
                        <div className="time-left-on-order-details__title">Total hours left on order</div>
                        <div className="time-left-on-order-details__item">{this.round2dec(this.getTotalHoursLeftOnOrder())}</div>
                        <div className="time-left-on-order-details__title">Total days left on order</div>
                        <div className="time-left-on-order-details__item">{this.round2dec(this.getTotalDaysLeftOnOrder())}</div>
                    </div>
                    }
                </div>
        );
    }
}

type Props = {
    timeLeftOnOrder: StoreTimeLeftOnOrder
};

type State = {
    actualsChecked: boolean,
    extraChecked: boolean,
    standbyChecked: boolean,
    interventionChecked: boolean
};

export default TimeLeftOnOrderDetails;
