import {ActionTypes} from '../types/actionTypes';
import {call, put, takeEvery} from 'redux-saga/effects';
import groupApi from '../api/groupApi';

function* getGroups(): Generator<any, any, any> {
    try {
        const groups = yield call(groupApi.getGroups);
        yield put({type: ActionTypes.GET_GROUPS_SUCCEEDED, payload: groups});
    } catch (e) {
        yield put({type: ActionTypes.GET_GROUPS_FAILED, payload: null});
    }
}

function* groupSaga(): Generator<any, any, any> {
    yield takeEvery(ActionTypes.GET_GROUPS_REQUESTED, getGroups);
}

export default groupSaga;
